























































import Vue from "vue";
import SubmitButton from "@/components/auth/SubmitButton.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { UserState } from "@/store/modules/auth/interfaces";
import SignupHeader from "@/views/auth/SignupHeader.vue";

import {
  GET_USER_DETAILS,
  USER_STATE,
  VERIFY_EMAIL
} from "@/store/modules/auth/constants";
import router from "@/router";
import { SEND_EMAIL, SITE_DIRECTION } from "@/store/modules/common/constants";
import {
  SendEmailPayload,
  SiteDirections
} from "@/store/modules/common/interfaces";
import { IApp } from "@/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";
export default Vue.extend({
  name: "VerifyEmailInception",
  components: { SubmitButton, SignupHeader },
  data() {
    return {
      decoration_img_src: require("@/assets/illustrations/email.svg"),
      verification_code: "",
      is_valid: false,
      send_email_loading: false,
      source_link:
        "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_10.webm",
      text_question: this.$t("candidate-onboarding.form.opt").toString(),
      resend_timer: 60, // Set the initial timer value to 60 seconds
      interval: null as any,
      email: ""
    };
  },
  async created() {
    const code = this.$route.params.code;
    if (code) {
      const decode = atob(this.$route.params.code).split("_&_");

      if (decode.length === 2) {
        this.verification_code = decode[1];
        this.email = decode[0];
      } else {
        this.set_root_error(`${this.$t("auth.invalid-code")}`);
      }
    }

    if (this.verification_code) {
      await this.submit_form();
    }

    if (this.get_site_direction === SiteDirections.RTL)
      this.source_link =
        "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_10.webm";
    else
      this.source_link =
        "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_10.webm";
    this.start_resend_timer();
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  watch: {
    verification_code(new_value: string) {
      this.is_valid = /^[0-9]{4}$/.test(new_value);
    },
    get_site_direction(value) {
      if (value === SiteDirections.RTL)
        this.source_link =
          "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/arabic/Question_10.webm";
      else
        this.source_link =
          "https://api-hcms-textract.s3.eu-west-2.amazonaws.com/open/bot/inception_registration/Question_10.webm";
      this.text_question = this.$t("candidate-onboarding.form.opt").toString();
    }
  },
  methods: {
    ...mapActions("common", {
      send_email: SEND_EMAIL
    }),
    ...mapActions("auth", {
      verify_email: VERIFY_EMAIL
    }),
    ...mapMutations("auth", {
      set_user_state: USER_STATE
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    async submit_form() {
      if (this.verification_code && this.verification_code.length === 4) {
        const response = await this.verify_email({
          token: this.verification_code,
          email: this.email || this.get_user_details.email
        });

        if (response) {
          this.set_user_state(UserState.EMAIL_VERIFICATION_COMPLETED);
          await router.push({
            name: "email-verified"
          });
          this.verification_code = "";
          this.is_valid = false;
        }
      }
    },
    async resend_code() {
      this.send_email_loading = true;
      const payload: SendEmailPayload = {
        type: IApp.EmailInvites.RESEND_EMAIL,
        user_ids: [this.get_user_details.id]
      };
      await this.send_email(payload);
      this.start_resend_timer(); // Start the timer
      this.send_email_loading = false;
    },
    start_resend_timer() {
      this.resend_timer = 60; // Reset the timer to 60 seconds
      this.interval = setInterval(() => {
        if (this.resend_timer > 0) {
          this.resend_timer--; // Decrement the timer
        } else {
          clearInterval(this.interval as number);
        }
      }, 1000);
    }
  }
});
